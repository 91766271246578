td {
  border: 1px solid rgba(0, 0, 0, 0.045);
}

::-webkit-scrollbar {
  width: 5.9px;
  height: 5.9px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #00000059;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: #00000091;
}

.input-desativado *:disabled,
.input-desativado * {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.884) !important;
}

tr {
  height: 49.5px;
}

/* Animações */
@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.tr-skeleton {
  background-color: rgba(0, 0, 0, 0.11);
  -webkit-animation: animation-c7515d 2s ease-in-out 0.5s infinite;
  animation: animation-c7515d 2s ease-in-out 0.5s infinite;
}

.fk-visualizar-form * {
  pointer-events: none;
}

@media (max-width: 768px) {
  .fk-container {
    padding: 0 !important;
  }

  .fk-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fk-item-text {
    display: contents;
  }

  .fk-dialog-actions {
    
  }
}
